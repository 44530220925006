import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import codeofConductPoliciesData from "../utils/data/codeofConductPoliciesData";

export default function CodeofConductPolicies() {
  return (
    <>
      <BreadCrumb pageName="CodeofConductPolicies" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Code of Conduct & Policies" />
        <TabPanel tabsData={codeofConductPoliciesData} />
      </div>
    </>
  );
}
