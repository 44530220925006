import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import advicetoShareholdersData from "../utils/data/advicetoShareholdersData";

export default function AdviceShareholders() {
  return (
    <>
      <BreadCrumb pageName="AdviceShareholders" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Advice to Shareholders" />
        <TabPanel tabsData={advicetoShareholdersData} />
      </div>
    </>
  );
}
