export default function DataRetentionPolicy() {
    return(
        <div className='md:mx-96 mx-4 my-8'>
            <h1 className="font-bold text-3xl">Data Retention Policy</h1>
            <div className="font-semibold mt-4">1. What is pre-payment or foreclosure? </div>
            <div>Record retention is a complex subject as there is no one place where all records can be found, they are scattered as per the requirements of different legislation and applicable laws to the Company. </div>
            <div>Document retention means the manner of retaining the documents of Golden Legand Leasing & Finance Ltd. (hereinafter referred as “the Company / Company”) and the time period up to certain documents are to be retained by the Company and the time period when such documents can be allowed to be purged. </div>
            <div>The Record Retention Policy aims at streamlining this and ensuring the smooth functioning of the Company. </div>
            <div className="font-semibold mt-4">Its core aspect is as follows: </div>
            <div>a. Nature and types of documents to be preserved/archived and retrieved </div>
            <div>b. Duration and circumstances under which they must be preserved </div>
            <div>c. Security and confidentiality of these records/documents </div>
            <div>d. Mode of destruction including but not limited to electronic and </div>
            <div className="font-semibold mt-4">2. Legal Requirement </div>
            <div>Regulation 9 of the Securities and Exchange Board of India (Listing Obligations and Disclosure Requirements) Regulations, 2015 (hereinafter referred to as “SEBI Listing Regulations”) mandates that a listed entity shall have a policy for preservation of documents, approved by its board of directors, classifying them in at least two categories as follows- </div>
            <div>a. documents whose preservation shall be permanent in nature ;</div>
            <div>b. documents with a preservation period of not less than eight years after completion of the relevant transactions. </div>
            <div>Provided that the listed entity may keep documents specified in clauses (a) and (b) in electronic mode. </div>
            <div>Further Regulation 30 (8) of the SEBI Listing Regulations also refers to an archival policy as per which all events or information which has been disclosed to stock exchange(s) under regulation 30 shall be hosted on the website of the Company for a minimum period of five years and thereafter as per the archival policy of the company, as disclosed on its website. </div>
            <div>Besides the above, as per applicable provisions of Companies Act, 2013 certain documents must be preserved permanently or up to a certain prescribed time.</div>
            <div>In addition to the above, Prevention of Money-Laundering (Maintenance of Records) Rules, 2005 read with the Master Direction - Know Your Customer (KYC) Direction, 2016 as issued by the Reserve Bank of India and as amended from time to time specifies that the Company shall maintain, preserve and report customer account information, with reference to provisions of Prevention of Money Laundering Act and its rules. </div>
            <div>In terms of Section 44AA of the Income Tax Act, 1961 (“IT Act”)Assesses are required to preserve the specified books of account for a period of 6 years from the end of the relevant assessment year, i.e., for a total period of 8 previous years. The period of 6 years gets extended if the assessment is reopened u/s. 147 of the IT Act, till the time assessment is completed. Transfer Pricing documents and information specified under Rule 10D must be maintained for a period of 8 years from the end of the relevant assessment year, i.e., for a total period of 10 previous years. Under amended Section 147 of the IT Tax, the reassessment can now be done upto 10 years from the end of relevant year and accordingly the data is required to be maintained for 11 years. If there is any appeal or any other litigation for any relevant assessment year then we are required to maintain such details till the issue is resolved Further, the Goods and Services Act read with rules thereunder states that every registered person shall keep and maintain at his Principal Place of Business few records, viz. Invoices, Input Tax Credit Availed, Input register, Output register etc. for a period not less than 6 years from the date of filing of relevant Annual Return. If there is any appeal, show cause or any other litigation then we are required to maintain such details till the issue is resolved. Recently, there is an order of the Hon'ble Supreme Court on account of the covid-19 pandemic, all statutory deadlines starting from 15.03.2020 till 28.02.2022 were excluded for the purpose of limitation. Accordingly, the period of book keeping will increase by nearly 2 years. </div>
            <div>The Company had adopted a Policy on preservation and archiving of the Documents prepared in line with the requirements of SEBI Listing Regulations. In order to standardise record retention process across function, the Record Retention Policy (“Policy”) replaced the policy on preservation and archiving of documents dated November 04, 2015 which was specifically applicable to Secretarial function. </div>
            <div>One of the pre-requisites of good records management is to ensure that records are neither prematurely destroyed nor kept for periods longer than required - at the cost of economy and efficiency. This policy has been framed keeping in view particularly the requirements of SEBI Listing Regulations, provisions of Companies Act, 2013, RBI Guidelines and other regulators (under whose gamut all the functions of the Company are subjected to). The Policy requires to provide for the systematic retention and destruction of documents received or created by the Company. This Policy addresses all documents regardless of physical or electronic form, and this Policy helps understand how long each type of document should be kept.</div>
            <div className="font-semibold mt-4">3. Definitions </div>
            <div><span className="font-semibold">a. “Applicable Laws”</span> includes any statute, law, SEBI listing regulations, Companies Act, 2023, RBI Guidelines, regulation, ordinance, rule, judgment, order, decree, bye-law, clearance, directive, guideline, policy, requirement, notifications and clarifications or other governmental instructions and/or mandatory standards as may be applicable to the Company from time to time. </div>
            <div><span className="font-semibold">b. “Authorised Person”</span> The Functional Heads of the Company or delegate(s) one level below the Functional Head are required to preserve the documents for that Function/Department who is generally expected to observe the compliance of preservation of Documents as per the requirements of Applicable laws </div>
            <div><span className="font-semibold">c. “Board”</span> means the Board of Directors of the </div>
            <div className="font-semibold">“Books of account” includes records maintained in respect of—</div>
            <div>a. all sums of money received and expended by a company and matters in relation to which the receipts and expenditure take place;</div>
            <div>b. all sales and purchases of goods and services by the company;</div>
            <div>c. the assets and liabilities of the company; and</div>
            <div>d. the items of cost as may be prescribed under section 148 of the Companies Act, 2013 in the case of a company which belongs to any class of companies specified under that section </div>
            <div><span className="font-semibold">e. “Company”</span> means Golden Legand Leasing & Finance Ltd. </div>
            <div><span className="font-semibold">f. “Current Document(s)”</span> means any Document that still has an ongoing relevance with reference to any ongoing litigation, proceedings, complaint, dispute, contract or any like matter. </div>
            <div><span className="font-semibold">g. “Document(s)”</span> includes but not limited to papers, notes, agreements, notices, advertisements, requisitions, orders, declarations, forms, correspondence, minutes, indices, registers and or any other record, required under or in order to comply with the requirements of any Applicable Law, whether issued, sent, received or kept in pursuance of the Applicable Law or under any other law for the time being in force or otherwise, maintained on paper or in electronic form. </div>
            <div><span className="font-semibold">h. “Electronic Record(s)”</span>  means the electronic record as defined under clause (t) of sub-section (1) of section 2 of the Information Technology Act, 2000. </div>
            <div><span className="font-semibold">i. “Electronic Form”</span> means any contemporaneous electronic device such as computer, laptop, compact disc, floppy disc, space on electronic cloud, or any other form of storage and retrieval device, considered feasible, whether the same is in possession or control of the Company or otherwise the Company has control over access to it. </div>
            <div><span className="font-semibold">j. “Maintenance” </span> means keeping Documents, either physically or in Electronic </div>
            <div><span className="font-semibold">k. “Preservation”</span> means to keep in good order and to prevent from being altered, damaged or </div>
            <div><span className="font-semibold">l. “Records”</span> means documentary evidence of past events or </div>
            <div className="font-semibold mt-4">4. Applicability</div>
            <div>This Policy is extended to all business segment functions and departments of the Company. The Policy will apply to all employees of the Company, and it covers all the records/documents issued/held by the Company. Roles and responsibilities of the Company’s employees, scope of activities, scope of operations and the monitoring mechanism of record retention shall be covered in Standard Operating Procedure (SOP) of respective functions/ departments.</div>
            <div className="font-semibold mt-4">5. Guidelines for Record Maintenance</div>
            <div className="font-semibold">5.1 Classification of documents</div>
            <div>The preservation of documents of the Company, as approved by the Board of Directors of the Company, should be as per the following classification:</div>
            <div>a. Documents whose preservation shall be permanent in nature;</div>
            <div>b. Documents with preservation period of not less than eight (8) years after completion of the relevant transactions</div>
            <div>c. The following overriding matters may be taken into account while setting retention The onus is on the management to ensure that the requirements of (a), (b), and (c) are adequately met</div>
            <div>Compliance with statutory and regulatory requirements;</div>
            <div>Satisfaction of the needs of regulatory inspectors to have access to certain records; and</div>
            <div>Satisfaction of the needs of internal and external auditors to have access to certain</div>
            <div>The indicative list for class of documents and the time period for which it is required to be maintained is provided in <span className="font-semibold">Annexure A.</span></div>
            <div className="font-semibold mt-4">5.2 Modes of Preservation</div>
            <div>a. The documents may be preserved in physical or electronic Insofar as practical and possible, after taking into consideration the legislative and regulatory requirement, the documents shall be preserved in electronic form. </div>
            <div>b. Authorised Person is required to preserve the documents for that Function/Department who is generally expected to observe the compliance of preservation of Documents as per the requirements of Applicable laws. </div>
            <div>c. The preservation of documents should be such as to ensure that there is no tampering, alteration, destruction or anything which endangers the content, authenticity, utility or accessibility of the</div>
            <div>d. The preserved documents must be accessible at all reasonable times. Access may be controlled by Authorized Person from each respective functions/departments, so as to ensure integrity of the documents and prohibit unauthorized access.</div>
            <div className="font-semibold mt-4">5.3 Custody of Documents</div>
            <div>a. Subject to the Applicable Law, the custody of the documents shall be with the Authorised Person. Where the Authorised Person tenders resignation such Person shall hand over all the relevant documents, lock and key, access control or password, or USB storage drives/pen drive/hard disk/one drive data/other storage devices or such other documents and devices in his possession under the Policy.</div>
            <div>b. Functional Heads and/or one level below the Functional Head, shall be responsible for ensuring compliance of this Policy, as related to records in their possession, custody or</div>
            <div className="font-semibold mt-4">5.4 Maintenance and preservation of records</div>
            <div>The following steps shall be taken regarding maintenance, preservation and reporting of customer account information:</div>
            <div>1. Maintain all necessary records of transactions (domestic and international, if applicable) between the Company and the customer for a minimum of ten years from the date of transaction. Records specific retention periods will be defined in business/department level</div>
            <div>2. Preserve the records pertaining to the identification of the customers and their addresses obtained while opening the account and during the course of business relationship, for at least five years after the business relationship is ended;</div>
            <div>3. Make available swiftly identification records and transaction data to the competent authorities upon request;</div>
            <div>4. Maintain a system of maintaining proper record of transactions as prescribed under Rule 3 of Prevention of Money Laundering (Maintenance of Records) Rules, 2005 (“PML Rules”)</div>
            <div>5. Maintain all necessary information in respect of transactions prescribed under Rule 3 of PML Rules so as to permit reconstruction of individual transaction, including the following:</div>
            <div className="mt-4">a. The nature of the transactions;</div>
            <div>b. The amount of the transaction and the currency in which it was denominated;</div>
            <div>c. The date on which the transaction was conducted; and</div>
            <div>d. The parties to the</div>
            <div className="mt-4">6. Evolve a system for proper maintenance and preservation of account information in a manner that allows data to be retrieved easily and quickly whenever required or when requested by the competent authorities;</div>
            <div>7. Maintain records of the identity and address of their customer, and records in respect of transactions referred to in Rule 3 of PML Rules in hard or soft format.</div>
            <div>8. If the Company intends to maintain records in offshore locations, it will seek prior approval of the RBI.</div>
            <div>9. The Company shall maintain information in respect of transactions with its client in hard and/or soft copies</div>
            <div>10. The Company shall ensure that records pertaining to the identification of customers and agents and their addresses (e.g. copies of documents like passports, identity cards, driving licences , PAN Card, utility bills ) obtained while opening the account and during the course of business relationship are properly preserved for at least five years after the business relationship is ended. Unless there is any litigation instituted or any threatened litigation, by or against the Company with respect to any customer, the documents/ records referred herein shall be stored only in electronic form.</div>
            <div>11. The Company shall ensure that records relating to all complex and all unusual large transactions and all unusual pattern of transactions which have no apparent economic or visible lawful purpose are preserved for eight years.</div>
            <div className="font-semibold mt-4">5.5 Place of storage</div>
            <div>a. The Company shall identify and have an approved place of storage to maintain all records</div>
            <div>b. The selected location must be secure and fire</div>
            <div>c. A well-defined reference/inventory of all documents archived with information on its physical/electronic location should be maintained.</div>
            <div>d. The Company may outsource its document storage activity, however this must be done after carrying out due diligence and verification. Further the Company shall enter into arrangements with the outsourced vendors/agencies after entering into an appropriate agreements and in compliance with the applicable RBI regulations pertaining to outsourcing of activities.</div>
            <div className="font-semibold mt-4">5.6 Retrieval of documents</div>
            <div>At the time of retrieval of a document or sets of documents, the Company shall ensure the following:</div>
            <div>a. Define an approval and authority matrix to ensure that only legitimate requests for records retrieval are made</div>
            <div>b. Define a time frame within which the documents will be ready for pick-up or delivery</div>
            <div>c. For physical documents, ensure that the time frame communicated includes the time involved in identifying the location of the archived document and the time to physically retrieve the document from the location where it is stored</div>
            <div>d. For electronic documents, ensure that there is no loss or damage at the time of transmission of information</div>
            <div className="font-semibold mt-4">5.7 Digitisation of Physical Records</div>
            <div>The guidelines for determining retention period for electronic records shall be the same as that for physical records. If hard copies are required in order to comply with litigation etc. electronic copies too shall be kept.</div>
            <div>The physical documents preserved may be converted, whenever required or felt necessary, into electronic form to ensure ease in maintenance of records and efficient utilization of space. Physical records may be digitised as per following category:</div>
            <div>1. Physical records to preserved permanently;</div>
            <div>2. Records of secondary importance and have a reference value for a limited period - 10 years or as may be applicable.</div>
            <div>Physical Documents once digitised, may be destroyed, provided, record keeping of those documents are not mandated by law. Further document owner to take ownership of the proper digitisation of physical records, when physical records are converted into electronic form.</div>
            <div>Digitisation of Physical record and destruction of such physical record will be done as per this Policy.</div>
            <div className="font-semibold mt-4">5.8 Destruction of documents</div>
            <div>a. The Company will have in place appropriate controls over the destruction of records upon expiry of the period set out in the policy statement. Such controls will include:</div>
            <div>1. Authorisation and approval prior to</div>
            <div>2. Establishment of procedures for destruction of physical documents/records (whether in-house or outsourced).</div>
            <div>3. Maintenance of records in electronic media as per the Company's Information and Cyber Security policy.</div>
            <div>4. A register giving details of the documents in paper/electronic/other form destroyed, the date and means of destruction; the format of the register has to be in accordance with <span className="font-semibold">Annexure D.</span></div>
            <div>b. Destruction as a normal administrative practice usually occurs because the records are duplicated, unimportant or for short term use only. This applies to both physical and electronic</div>
            <div>c. Temporary documents, excluding the documents in current use shall be destroyed after the relevant or prescribed period, by the Authorised Person in whose custody the documents are stored, after the prior approval of the Board or any other authority, if specifically required under the applicable laws/regulations pursuant to which the documents have been preserved. <span className="italic">(Refer <span className="font-semibold">Annexure C</span> for the categories of documents which may be destroyed as a normal administrative practice)</span></div>
            <div>d. The entries in the digital register shall be authenticated by the Authorised</div>
            <div>e. The documents may be destroyed as follows:</div>
            <div>1. Recycle non-confidential paper records; or</div>
            <div>2. Shred or otherwise render unreadable confidential paper records; or</div>
            <div>3. Delete or destroy electronically stored</div>
            <div className="font-semibold mt-4">5.9 Archiving of Documents which are hosted on the Company's website</div>
            <div>Recognizing the need to ensure the preservation and availability of the documents of the Company after their required regulatory preservation period, for any legal, administrative, and historical purposes, the Company adopts the following archival mechanism in respect of documents which are hosted on the website of the Company:-</div>
            <div>a. All documents generated, disclosed or received by the Company, on its website, for the purpose of shareholder/ any other security holder communication, are the properties of the Company and constitute archival material.</div>
            <div>b. Archival material of the Company shall not be destroyed without the approval of the Authorised</div>
            <div>Provided that nothing contained herein shall be deemed to lead to an exception in case of an accidental deletion, or deletion due to any system flaw, virus, or any other deletion, inaccessibility or loss due to any reason other than deliberate and determinate deletion.</div>
            <div>a. Material so selected for preservation shall be sent to the Company</div>
            <div>b. The Authorised Person will be responsible for deciding how long archival material is to be retained in and under the direct control of the officer concerned, if the law does not specify any time period. The period shall not be less than 3 years.</div>
            <div>c. With reference to the pretext, the Documents submitted to the stock exchange, to be hosted on the website for the purpose of compliance with disclosure norms shall also be archived.</div>
            <div>1. Archiving of the Documents submitted to the stock exchange, in terms of the Regulations, shall be done after the lapse of the preservation period of 5 years as specified in Regulation 30(8) of SEBI Listing Regulations.</div>
            <div>2. For the Documents to be submitted to the stock exchange to comply with disclosure norms as required by any other Applicable Law, the Documents are to be archived after the lapse of the specified/required time period.</div>
            <div className="font-semibold mt-4">5.10 Record keeping for IT related applications</div>
            <div>The Record Retention policy shall be in line with other policies covering operations of the Company. Particularly, in case of record keeping in the electronic form, the record maintenance policy may be in line with the general Information Technology and Information System Security best practices. Digital records (soft copies) shall be categorized basis its importance and prioritization, aligning to the Organisation, Regulatory, Statutory and Legal requirements.</div>
            <div>Retention of the records need to be maintained in a secure IT environment with access to only restricted and authorized personnel. During retention, appropriate IT security controls are to be implemented to ensure protection against unauthorized access, data pilferage or record corruption.</div>
            <div>Backup should be taken for important records, as a fallback option, if</div>
            <div>Disposal of records should be done in a safe and secure manner, once the validity of retention period is over as per Policy.</div>
            <div className="font-semibold mt-4">5.11 Review of maintenance of records</div>
            <div>Maintenance of records may be reviewed for adequacy against the following criteria:</div>
            <div>Content - what data is retained</div>
            <div>Frequency - intervals between updates</div>
            <div>Security - protection from unauthorised access</div>
            <div>Durability - safekeeping/protection from deterioration</div>
            <div>Accessibility - ready availability to authorised individuals</div>
            <div>Lag - delay between initiating and recording</div>
            <div>Accuracy - reliability and freedom from error</div>
            <div>Verification - ability to check</div>
            <div className="font-semibold mt-4">5.12 Authenticity </div>
            <div>Where a document is being maintained both in physical form and in Electronic form, the authenticity with reference to the original physical form should be considered for every purpose.</div>
            <div className="font-semibold mt-4">5.13 Interpretation</div>
            <div>In any circumstance where the terms of this Policy differ from any existing or newly enacted law, rule, regulation or standard governing the Company, the law, rule, Regulation or standard will take precedence over these policies and procedures until such time as this Policy is changed to conform to the law, rule, Regulation or standard.</div>
            <div className="font-semibold mt-4">6. Policy Review & Updates</div>
            <div>The policy shall be reviewed by the Risk Management Committee as and when required for incorporating regulatory updates and changes, if any.</div>
            <div className="font-semibold mt-4">7. Annexures</div>
            <div>Annexure A - Indicative list</div>
            <table className="border-collapse border m-4">
                <thead>
                    <tr>
                        <th className="border p-4">PERMANENT PRESERVATION</th>
                        <th className="border p-4">TEMPORARY PRESERVATION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-left p-4">I. Companies Act, 2013</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · The Memorandum and Articles of the company <br />
                            · Affidavit from the subscribers to the Memorandum and from persons named as the first directors <br />
                            · Register of members <br />
                            · Books and Documents relating to the issue of share certificates, including the blank forms of share certificates <br />
                            · The foreign register of members, if applicable <br />
                            · Minutes book of Board meetings, Committee meeting and general meeting <br />
                            · Register of Charges <br />
                            · Register of loans, guarantee, security and acquisition made by the company <br />
                            · Register of investments not held in its own name by the company <br />
                            · Register of contracts with related party and contracts and Bodies etc. in which directors are interested
                        </td>
                        <td className="border p-4">
                            · The Memorandum and Articles of the company <br />
                            · Affidavit from the subscribers to the Memorandum and from persons named as the first directors <br />
                            · Register of members <br />
                            · Books and Documents relating to the issue of share certificates, including the blank forms of share certificates <br />
                            · The foreign register of members, if applicable <br />
                            · Minutes book of Board meetings, Committee meeting and general meeting <br />
                            · Register of Charges <br />
                            · Register of loans, guarantee, security and acquisition made by the company <br />
                            · Register of investments not held in its own name by the company <br />
                            · Register of contracts with related party and contracts and Bodies etc. in which directors are interested
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">II. Secretarial Standard ( SS-1 and SS- 2)</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · Minutes of Board & Committee meetings and General meetings
                        </td>
                        <td className="border p-4">
                            · Office copies of Notices, scrutiniser's report, and related papers (as long as they remain current or for 8 financial years), whichever is later.
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                        </td>
                        <td className="border p-4">
                            · Office copies of Notices, scrutiniser's report, and related papers (as long as they remain current or for 10 years or as long as they remain current).
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="border-collapse border m-4">
                <thead>
                    <tr>
                        <th className="border p-4">PERMANENT PRESERVATION</th>
                        <th className="border p-4">TEMPORARY PRESERVATION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-left p-4">III. SEBI</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · Application for issuance and listing of securities
                        </td>
                        <td className="border p-4">
                            · All event-based disclosures <br />
                            · Quarterly, Half Yearly and Annual Reports filed with the stock excahnges <br />
                            · All stock exchanges intimation or material disclosures. <br />
                            To be kept for period of 8 years or as defined under the SEBI Listing Regulations, as amended
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">IV. Taxation</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · Income tax returns and filings <br />
                            · Tax Exemption and Related documents <br />
                            · PAN, TAN, GST certificates
                        </td>
                        <td className="border p-4">
                            · Excise/Customs/GST Records <br />
                            · Tax Deducted at Source Records <br />
                            · Income Tax papers/records <br />
                            · Service Tax papers/records <br />
                            · VAT / CST / State Sales Tax/GST papers and records <br />
                            · Customer and Vendor data records <br />
                            · Copy of Output and Input invoices <br />
                            · Accounting records based on which Income Tax/GST/Service Tax returns are filed and Tax Audit/GST Audit are being conducted. <br />
                            Above records needs to be maintained from 11 years from the end of the Financial Year or completion of assessment under the applicable law is over whichever is later. <br />
                            In case any appeal/litigation for the year is pending then these records must be kept for one year post the completion of the final appeal. <br />
                            *The Corana period i.e 15.03.20 to 28.02.22 shall be excluded from calculating the above limitation period
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">V. Legal</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · Court Orders- <br />
                            · Patents, trademark registrations, copyright registrations <br />
                            · Business / JV/SPA/Loan/Lease Agreements <br />
                            · Legal Opinions. <br />
                            · Service Agreements, Non Disclosure Agreements, Letter of Engagements, Memorandum of Understandings, Contracts. <br />
                            Above records needs to be maintained from 11 years from the end of the Financial Year or completion of assessment under the applicable law is over whichever is later.
                        </td>
                        <td className="border p-4">
                            · Contracts, Agreements and Related correspondence (including any proposal that resulted in the contract and other supportive documentation), unless the matter is under litigation. <br />
                            · Miscellaneous - Legal Memoranda subject matter files (3 years after the close of the matter) <br />
                            · Miscellaneous - Litigation files <br />
                            Above records needs to be maintained from 11 years from the end of the Financial Year or completion of assessment under the applicable law is over whichever is later
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="border-collapse border m-4">
                <thead>
                    <tr>
                        <th className="border p-4">PERMANENT PRESERVATION</th>
                        <th className="border p-4">TEMPORARY PRESERVATION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="text-left p-4">VI. Customer Service</th>
                    </tr>
                    <tr>
                        <td className="border p-4 invisible">
                            · Call recording of customer's calls received at Call Centre for a period of 10 years <br />
                            · Electronic data of customer interactions for a period of 10 years
                            · Call recording of customer's calls received at Call Centre for a period of 10 years
                        </td>
                        <td className="border p-4">
                            · Call recording of customer's calls received at Call Centre for a period of 10 years <br />
                            · Electronic data of customer interactions for a period of 10 years
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">VII. Regulatory Compliance (RBI)</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · Certificate issued by RBI <br />
                            · Form FC - GPR, FC - TRS, ARF etc.
                        </td>
                        <td className="border p-4">
                            · Returns filed with RBI (10 years from date of filing with the RBI)
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">VIII. HR</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · Salary Register/Payroll Registers <br />
                            · Bonus & Gratuity Records <br />
                            · Statutory Registers and other Statutory Records
                        </td>
                        <td className="border p-4">
                            · Statutory Returns / Challans - PF, ESI, P. Tax Etc. - Duration 15 Years. <br />
                            · Employee File - Joining Docs, KYCs, Appointment Letter, Increment / Promotion Letters - Duration 7-10 Years. <br />
                            · Disciplinary Action Proceeding - Domestic Enquiry etc. - Duration 7 Years.
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">IX. Accounts & Finance</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · Audit reports
                        </td>
                        <td className="border p-4">
                            · Books of accounts including vouchers and bills (12 years) <br />
                            · Bank Statements and Reconciliations (11 years) <br />
                            · Payroll compliance related challans such as PF. ESI. P. Tax (11 years) <br />
                            If there is any appeal or any other litigation for any relevant assessment year then we are required to maintain such details till the issue is resolved
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">X. Business/Ops</th>
                    </tr>
                    <tr>
                        <td className="border p-4 invisible">
                            · KYC records/records of Identification of the customers and their addresses obtained while opening the account and during the course of business relationship, for at least five years after theionship is ended <br />
                            · Customer loan files & documents for a period of 10 years <br />
                            Note: For the purpose of this Section, the expressions “records pertaining to the identification”, “identification records”, etc., shall include updated records of the identification  
                        </td>
                        <td className="border p-4">
                            · KYC records/records of Identification of the customers and their addresses obtained while opening the account and during the course of business relationship, for at least five years after the business relationship is ended <br />
                            · Customer loan files & documents for a period of 10 years <br />
                            Note: For the purpose of this Section, the expressions “records pertaining to the identification”, “identification records”, etc., shall include updated records of the identification  data,  account  files  and results of any analysis undertaken.
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">XI. Administration & Corporate Communication</th>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            · Original Purchase and Sale Agreement <br />
                            · Property Card, Index II, Ownership records issued by Government Authority <br />
                            · Property Insurance
                        </td>
                        <td className="border p-4">
                            · All PO and Bills of payment (As per taxation requirement) <br />
                            · Licenses/permissions issued by Government Authority (As per taxation requirement)
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4 invisible">
                        If there is any appeal or any other litigation for any relevant assessment year then we are required to maintain such details till the issue is resolved
                        </td>
                        <td className="border p-4">
                        If there is any appeal or any other litigation for any relevant assessment year then we are required to maintain such details till the issue is resolved
                        </td>
                    </tr>
                    <tr>
                        <th className="text-left p-4">XII. Internal Auditor Reports</th>
                    </tr>
                    <tr>
                        <td className="border p-4 invisible">
                        Internal Audit Working Papers, Reports etc. to be preserved for a period of 8 Years.
                        </td>
                        <td className="border p-4">
                        Internal Audit Working Papers, Reports etc. to be preserved for a period of 8 Years.
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="font-semibold mt-4">Annexure B: Broad list of regulations and Acts considered</div>
            <table className="border-collapse border m-4">
                <thead>
                    <tr>
                        <th className="border p-4">Sr. No.</th>
                        <th className="border p-4">Name of legislations/circulars</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border p-4">
                            1
                        </td>
                        <td className="border p-4">
                        Anti-Money Laundering /Counter Financing of Terrorism (AML/CFT)-Guidelines for General Insurers dated February 08, 2013
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            2
                        </td>
                        <td className="border p-4">
                        Code on Social Security, 2020
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            3
                        </td>
                        <td className="border p-4">
                        Companies Act, 2013 read with rules made thereunder
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            4
                        </td>
                        <td className="border p-4">
                        Competition Act, 2002
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            5
                        </td>
                        <td className="border p-4">
                        Contract Labour (Regulation and Abolition) Act, 1970
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            6
                        </td>
                        <td className="border p-4">
                        Environment (Protection) Act, 1986 and E-Waste (Management) Rules, 2016
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            7
                        </td>
                        <td className="border p-4">
                        Income Tax Act, 1961 and Income Tax Rules, 1962
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            8
                        </td>
                        <td className="border p-4">
                        Indian Stamp Act, 1899, Amendment and Rules made thereunder
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            9
                        </td>
                        <td className="border p-4">
                        Information Technology Act, 2000 and Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            10
                        </td>
                        <td className="border p-4">
                        IRDAI (Registration of Corporate Agents) Regulations, 2015
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            11
                        </td>
                        <td className="border p-4">
                        Master Direction – External Commercial Borrowings, Trade Credits and Structured Obligations
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            12
                        </td>
                        <td className="border p-4">
                        Master Direction – Information Technology Framework for the NBFC
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            13
                        </td>
                        <td className="border p-4">
                        Master Direction – Know Your Customer (KYC) Direction, 2016 dated February 25, 2016
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            14
                        </td>
                        <td className="border p-4">
                        Master Direction – Monitoring of Frauds in NBFCs (Reserve Bank) Directions, 2016
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            15
                        </td>
                        <td className="border p-4">
                        Master Direction – Non-Banking Financial Company – Systemically Important Non-Deposit taking Company and Deposit taking Company (Reserve Bank) Directions, 2016
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            16
                        </td>
                        <td className="border p-4">
                        Master Direction- Non-Banking Financial Company Returns (Reserve Bank) Directions, 2016
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            17
                        </td>
                        <td className="border p-4">
                        Minimum Wages Act, 1948
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            18
                        </td>
                        <td className="border p-4">
                        Motor Vehicles Act, 1988 and Central Motor Vehicles Rules, 1989
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            19
                        </td>
                        <td className="border p-4">
                        Other Directions / Guidelines issued by RBI /IRDAI, as may be applicable on the Company from time to time
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            20
                        </td>
                        <td className="border p-4">
                        Payment of Bonus Act, 1965 and Payment of Bonus Rules, 1975
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            21
                        </td>
                        <td className="border p-4">
                        Securities and Exchange Board of India (Prohibition of Insider Trading) Regulations, 2015
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            22
                        </td>
                        <td className="border p-4">
                        Securities And Exchange Board of India Act, 1992, Securities Contracts (Regulation) Act, 1956 and Securities and Exchange Board of India (Listing Obligation and Disclosure Requirements) Regulations, 2015
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            23
                        </td>
                        <td className="border p-4">
                        Selection, Installation And Maintenance Of First-Aid Fire Extinguishers – Code Of Practice ( Fourth Revision)
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4">
                            24
                        </td>
                        <td className="border p-4">
                        Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 read with Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Rules, 2013
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="font-semibold mt-4">Annexure C: Categories of documents which may be destroyed as a normal administrative practice</div>
            <div>The following categories of documents may be destroyed as normal administrative practice <span className="italic">(indicative)</span>:</div>
            <div>a. Catalogues</div>
            <div>b. Copies of press cuttings, press statements or publicity material</div>
            <div>c. Letters of appreciation or sympathy</div>
            <div>d. Requests for copies of maps, plans, charts, advertising material (subject to clause on destruction of documents)</div>
            <div>e. Facsimiles where a photocopy has been made</div>
            <div>f. Telephone messages</div>
            <div>g. Drafts of reports, correspondence, speeches, notes, spread sheets, etc. (subject to clause on destruction of documents)</div>
            <div>h. Routine statistical and progress reports compiled and duplicated in other reports (subject to clause on destruction of documents)</div>
            <div className="font-semibold mt-4">Annexure D: Format of the digital register of Documents disposed/destroyed</div>
            <table className="border-collapse border m-4">
                <tbody>
                    <tr>
                        <td className="border p-4">
                        Particulars Of Document Alongwith Provision Of Applicable Law
                        </td>
                        <td className="border p-4">
                        Date And Mode Of Destruction
                        </td>
                        <td className="border p-4">
                        Initials Of The Authorised Person
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4 invisible">
                        Particulars Of Document Alongwith Provision Of Applicable Law
                        </td>
                        <td className="border p-4 invisible">
                        Date And Mode Of Destruction
                        </td>
                        <td className="border p-4 invisible">
                        Initials Of The Authorised Person
                        </td>
                    </tr>
                    <tr>
                        <td className="border p-4 invisible">
                        Particulars Of Document Alongwith Provision Of Applicable Law
                        </td>
                        <td className="border p-4 invisible">
                        Date And Mode Of Destruction
                        </td>
                        <td className="border p-4 invisible">
                        Initials Of The Authorised Person
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}