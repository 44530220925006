import { contactCardData } from "../utils/data/contactCardData";
import Container from "./Container";

export default function ContactCard() {
  return (
    <Container>
      <div className="flex flex-col items-center xl:items-start">
        {contactCardData.map((contact) => (
          <div
            key={contact.id}
            className="w-auto border md:min-h-52 min-h-64 rounded-xl my-4 hover:-translate-y-2 transition-transform hover:shadow-sm flex flex-col md:flex-row px-4 py-4 gap-x-4 items-start"
          >
            <contact.icon className="text-green-500 bg-gray-200 rounded-full w-14 h-14 md:w-16 md:h-12 p-2" />
            <div className="flex flex-col space-y-1">
              <h1 className="text-2xl font-bold">{contact.label}</h1>
              <h3 className="text-md font-semibold">{contact.designation}</h3>
              <p className="font-semibold text-sm text-gray-500 flex flex-wrap">
                <strong className="pr-2">Address:</strong>
                {contact.address}
              </p>
              <p className="font-semibold text-sm text-gray-500 flex flex-wrap">
                <strong className="pr-2">Phone No.:</strong>
                {contact.phoneno}
              </p>
              <p className="font-semibold text-sm text-gray-500 flex flex-wrap">
                <strong className="pr-2">Email:</strong>
                {contact.email}
              </p>
              <p className="font-semibold text-sm text-gray-500 flex flex-wrap">
                <strong className="pr-2">Website:</strong>
                {contact.website}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
