import React from "react";
import { ManagementCardProps } from "../helpers/types";

const ManagementCard: React.FC<ManagementCardProps> = ({ data }) => {
    return (
        <div className="relative w-96 h-64 bg-primary m-4 shadow-2xl flex flex-col justify-center items-center group">
            <img src={data.img} alt={data.name} width={150} className="rounded-full border" loading="lazy" />
            <div className="mt-4">{data.name}</div>
            <div className="text-white text-center">{data.designation}</div>
            <div className="absolute inset-0 bg-primary bg-opacity-90 flex flex-col justify-center items-center p-4 cursor-default opacity-0 group-hover:bg-secondary group-hover:opacity-100 transition-opacity duration-500">
                <p className="text-white">{data.data}</p>
            </div>
        </div>
    );
};

export default ManagementCard;
