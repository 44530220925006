import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import annualSecretarialComplianceData from "../utils/data/annualSecretarialComplianceData";

export default function AnnualSecretarialCompliance() {
  return (
    <>
      <BreadCrumb pageName="AnnualSecretarialCompliance" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Annual Secretarial Compliance Report" />
        <TabPanel tabsData={annualSecretarialComplianceData} />
      </div>
    </>
  );
}
