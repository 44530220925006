import BreadCrumb from "../components/BreadCrumb";
import bgImage from "../utils/images/milestoneBg-1.png";
import sideImage from "../utils/images/Banner_600X600_Vision-mission.png";
import Container from "../components/Container";

export default function Milestones() {
  return (
    <>
      <div
        className="bg-fixed bg-cover bg-no-repeat bg-top"
        style={{ backgroundImage: `url(${bgImage})`, height: "25rem" }}
      >
        <h1 className="text-5xl text-center font-semibold text-white pt-44">
          Milestones
        </h1>
      </div>
      <BreadCrumb pageName="Milestones" />
      <Container>
        <div className="sm:flex gap-8 lg:gap-16">
          <div className="w-full md:w-2/5">
            <img
              src={sideImage}
              alt="sideImage"
              className="md:mb-24 rounded-xl"
              loading="lazy"
            />
          </div>

          <div className="w-full md:w-3/5">
            <h2 className="text-xl lg:text-3xl font-bold mt-4">Milestones</h2>
            <p className="text-sm lg:text-lg mt-4 text-gray-600">
              In today's Digital times, FinTech is Dynamic. All platforms are
              constantly changing, and so are their milestones. We have
              different customer-centric projects under the Golden Legand
              Umbrella with different milestones and missions. With the core
              business of the NBFC, we are trying to reach the Indian small
              business population who are into lending to provide them with a
              comprehensive digital platform to organise their business and make
              a loan cooperative for expanding their customer base.
            </p>
            <p className="text-sm lg:text-lg mt-4 text-gray-600 mb-4">
              With our Projects like www.ashapurtiloans.com,and www.xoompay.in,
              We are trying to provide consumers with a user-friendly solution
              to conduct their day-to-day digital transactions most efficiently
              and economically. Our platforms, like www.thebigtech.net and
              www.zoompay.net, will provide secured and seamless investment in
              crypto and international funds transfer globally. We are
              developing the world’s first hybrid financial switch system and
              routing mechanism. For the first time, the integration of banking
              and eCommerce switch platforms is developed as a single
              cloud-based platform that removes the extra spending of banks and
              financial organisations on a large array of servers. This solution
              is available on the SAS model, fully cloud-based and in a
              pay-per-use format. Unlike our competitors in India and worldwide,
              all our platforms are secured with the latest PCI-DSS security
              format and compliant with the latest updated compliance of the
              federal banks and monitoring organisations of the specific
              countries where these platforms are used. In India, our Switch
              comes with OSS, AML, and eKYC modules to help cooperative banks
              and NBFCs to operate smoothly without the added hassle. We enjoy
              being a step ahead of our competitors in technological
              development, as a significant portion of our revenue is used for
              the research and development of these platforms.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
}
