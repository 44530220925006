import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import corporateGovernanceData from "../utils/data/corporateGovernanceData";

export default function CorporateGovernanceReport() {
  return (
    <>
      <BreadCrumb pageName="CorporateGovernanceReport" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Corporate Governance Report" />
        <TabPanel tabsData={corporateGovernanceData} />
      </div>
    </>
  );
}
