import React from "react";
import { ProjectPageProps } from "../helpers/types";
import { FaArrowCircleRight } from "react-icons/fa";
import Container from "../components/Container";

const ProjectPage: React.FC<ProjectPageProps> = ({ data }) => {
  return (
    <>
      <Container>
        <div className="sm:mx-32 my-8 space-y-4 group">
          <img src={data.imgSm} alt={data.imgSm} loading="lazy" />
          <img src={data.imgLg} alt={data.imgLg} loading="lazy" />
          {data.content.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          {data.link !== "" ? (
            <a
              href={data.link}
              className="flex flex-row justify-end text-primary font-semibold text-xl my-4 group-hover:text-secondary"
            >
              For More Informations Visit{" "}
              <FaArrowCircleRight className="text-primary ms-2 mt-1 group-hover:text-secondary" />
            </a>
          ) : (
            <></>
          )}
        </div>
      </Container>
    </>
  );
};

export default ProjectPage;
