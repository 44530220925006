import { useState, useEffect, useCallback } from "react";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import quoteImg from "../utils/images/Icon.png";
import { comments } from "../utils/data/commentsData";
import Container from "./Container";

const ClientSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(3);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Function to get margin class based on itemsToShow
  const getMarginClass = (items) => {
    switch (items) {
      case 1:
        return "m-4"; // Margin for mobile
      case 2:
        return "m-4"; // Margin for tablet
      case 3:
        return "m-2"; // Margin for desktop
      default:
        return "m-1";
    }
  };

  // Function to get width style based on itemsToShow
  const getWidthStyle = (items) => {
    switch (items) {
      case 1:
        return { flex: "0 0 calc(100% - 1rem)" }; // Full width minus margin for mobile
      case 2:
        return { flex: "0 0 calc(50% - 2rem)" }; // 50% width minus margin for tablet
      case 3:
        return { flex: "0 0 calc(33.333% - 2.3rem)" }; // 33.333% width minus margin for desktop
      default:
        return { flex: "0 0 calc(33.333% - 0.5rem)" };
    }
  };

  const margin = getMarginClass(itemsToShow);
  const widthStyle = getWidthStyle(itemsToShow);

  // Handle the next slide
  const nextSlide = useCallback(() => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % comments.length);
    }
  }, [isTransitioning]);

  // Handle the previous slide
  const prevSlide = useCallback(() => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + comments.length) % comments.length
      );
    }
  }, [isTransitioning]);

  // Handle resizing for responsiveness
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setItemsToShow(1);
      } else if (window.innerWidth < 1024) {
        setItemsToShow(2);
      } else {
        setItemsToShow(3);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Automatically scroll the slider
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [nextSlide]);

  // Handle transition reset
  useEffect(() => {
    if (isTransitioning) {
      const resetTransition = () => setIsTransitioning(false);

      if (currentIndex >= comments.length) {
        setTimeout(() => {
          setCurrentIndex(0);
          resetTransition();
        }, 500);
      } else if (currentIndex < 0) {
        setTimeout(() => {
          setCurrentIndex(comments.length - 1);
          resetTransition();
        }, 500);
      } else {
        setTimeout(resetTransition, 500);
      }
    }
  }, [currentIndex, isTransitioning]);

  return (
    <Container>
      <div className="flex md:flex-row flex-col justify-evenly my-24 pl-4 pr-4">
        <div className="flex flex-col items-center md:py-12">
          <div className="text-center md:text-left text-2xl font-extrabold md:w-44 self-center">
            What Our Clients Say About Us:
          </div>
          <div className="flex md:mt-12 mt-6 md:self-start self-center">
            <button
              onClick={prevSlide}
              aria-label="Previous Slide"
              className="icon-hover-fill"
            >
              <CiCircleChevLeft className="text-4xl text-primary" />
            </button>
            <button
              onClick={nextSlide}
              aria-label="Next Slide"
              className="icon-hover-fill"
            >
              <CiCircleChevRight className="text-4xl text-primary" />
            </button>
          </div>
        </div>
        <div className="relative md:w-[1150px] overflow-hidden py-12">
          <div
            className={`flex ${
              isTransitioning
                ? "transition-transform duration-500 ease-in-out"
                : ""
            }`}
            style={{
              transform: `translateX(-${(currentIndex * 100) / itemsToShow}%)`,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {comments.concat(comments).map((comment, index) => (
              <div key={index} className={`  ${margin}`} style={widthStyle}>
                <div className="rounded-3xl shadow-md border border-solid border-[#fbfbfb] px-8 pb-8 h-full md:h-[170px] lg:h-[220px] xl:h-[180px]">
                  <img
                    src={quoteImg}
                    alt="quote"
                    width={50}
                    className="rounded-md shadow-md shadow-slate-200 -translate-y-5 ms-5"
                    loading="lazy"
                  />
                  <div className="font-medium text-gray-500 text-md leading-6">
                    {comment.text}
                  </div>
                  <div className="flex absolute bottom-8 md:bottom-16">
                    {[...Array(comment.stars)].map((_, i) => (
                      <FaStar key={i} className="text-yellow-500" />
                    ))}
                  </div>
                </div>
                <div className="font-semibold text-md ms-8 mt-4 text-gray-800">
                  {comment.author}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ClientSlider;
