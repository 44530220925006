import { useState } from "react";
import Accordion from "./Accordion";
import { AiFillFilePdf } from "react-icons/ai";
import { TabsProps } from "../helpers/types";
import Container from "./Container";

const TabPanel = ({ tabsData }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(tabsData[0]?.id || 0);
  const [transition, setTransition] = useState<boolean>(false);

  const handleTabClick = (tabId: number) => {
    if (tabId !== activeTab) {
      setTransition(true);
      setTimeout(() => {
        setActiveTab(tabId);
        setTransition(false);
      }, 300);
    }
  };

  const activeTabData = tabsData.find((tab) => tab.id === activeTab);

  return (
    <Container>
      <div className="flex items-center mb-4 gap-2">
        {tabsData.map((tab) => (
          <div
            key={tab.id}
            className={`cursor-pointer px-4 py-2 hover:bg-primary hover:text-white hover:rounded-lg transition duration-300 ease-in-out ${
              activeTab === tab.id
                ? "bg-primary text-white rounded-lg shadow-lg"
                : "bg-slate-100 text-gray-600 rounded-lg"
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div
        className={`py-4 transition-opacity duration-300 ease-in-out ${
          transition ? "opacity-0" : "opacity-100"
        }`}
      >
        {!activeTabData?.hasAccordion ? (
          // Custom design for Tab 2
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
            {activeTabData?.accordionItems?.map((accordionItem, index) =>
              accordionItem.quarters.map((quarter, qIndex) =>
                quarter.reports.map((report, rIndex) => (
                  <a
                    key={`${index}-${qIndex}-${rIndex}`}
                    href={report.file}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="border rounded-lg p-4 bg-gray-50 hover:bg-primary qtpdfBtn group flex items-center"
                  >
                    <div className="flex items-center justify-center">
                      <AiFillFilePdf className="mr-1 text-4xl text-red-500 group-hover:text-white" />
                      <h3 className="text-base font-semibold w-full text-left group-hover:text-white ml-4 ">
                        {accordionItem.title}
                      </h3>
                    </div>
                  </a>
                ))
              )
            )}
          </div>
        ) : activeTabData?.accordionItems ? (
          <Accordion
            items={activeTabData.accordionItems}
            initialOpenIndex={0} // Open the first accordion item by default
          />
        ) : (
          <div>{activeTabData?.content}</div>
        )}
      </div>
    </Container>
  );
};

export default TabPanel;
