import { FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { contactCardDataItems } from "../../helpers/types";

export const contactCardData: contactCardDataItems[] = [
  {
    id: 1,
    icon: IoMdMail,
    label: "StakeHolders",
    designation: "Company Secretary",
    address:
      "Unit 202, Shri Ramakrishna Chambers, Plot No. 67B, TPS - IV, Linking Road,Khar (W), Mumbai, Maharashtra, 400052.",
    phoneno: "022 - 65023342",
    email: "cs@gllfl.com",
    website: "www.gllfl.com",
  },
  {
    id: 2,
    icon: FaPhoneAlt,
    label: "Investors",
    designation: "RTA",
    address:
      "Satellite Corporate Services Pvt. Ltd. B-302, Sony Apartment, Opp. St. Judge's High School, Off. Andheri - Kurla Road, Jarimari, Sakinaka, Mumbai, Maharashtra, 400072.",
    phoneno: "022 - 28220461 / 022 - 28511809",
    email: "service@satellitecorporate.com",
    website: "www.satellitecorporate.com",
  },
  {
    id: 3,
    icon: FaLocationDot,
    label: "Business Queries",
    designation: "Manager",
    address:
      "Unit 202, Shri Ramakrishna Chambers, Plot No. 67B, TPS - IV, Linking Road,Khar (W), Mumbai, Maharashtra, 400052.",
    phoneno: "022 - 65023342",
    email: "contact@gllfl.com",
    website: "www.gllfl.com",
  },
];
