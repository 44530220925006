import BreadCrumb from "../components/BreadCrumb";
import teamImage from "../utils/images/Office-Group-Photo-1.jpg"

export default function OurTeam() {
    return(
        <>
        <BreadCrumb pageName="Our Team" />
        <div className="flex flex-row justify-center my-8">
            <img src={teamImage} width={1500} alt="teamImage" className="self-center" loading="lazy" />
        </div>
        </>
    );
}