import { InvestorsCornerProps } from "../helpers/types";
import Container from "./Container";

export default function InvestorsCorner({ title }: InvestorsCornerProps) {
  return (
    <Container className="px-0">
      <div className="mb-5">
        <h1 className="text-2xl font-bold">{title}</h1>
      </div>
    </Container>
  );
}
