import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import compositionCommittee from "../utils/data/compositionCommittee";

export default function CompositionCommittee() {
  return (
    <>
      <BreadCrumb pageName="CompositionCommittee" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Composition of Committee" />
        <TabPanel tabsData={compositionCommittee} />
      </div>
    </>
  );
}
