import BreadCrumb from "../components/BreadCrumb";
import InvestorsCorner from "../components/InvestorsCorner";
import TabPanel from "../components/TabPanel";
import shareholdingPatternData from "../utils/data/shareholdingPatternData";

export default function ShareholdingPattern() {
  return (
    <>
      <BreadCrumb pageName="ShareholdingPattern" />
      <div className="flex flex-col items-center mb-6">
        <InvestorsCorner title="Shareholding Pattern" />
        <TabPanel tabsData={shareholdingPatternData} />
      </div>
    </>
  );
}
