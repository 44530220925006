import { useState, useRef, lazy, Suspense } from 'react';
import { JobFormDatas } from "../helpers/types";
import { submitApplication } from '../helpers/api';
import { useParams } from 'react-router-dom';
import emailjs from '@emailjs/browser';
const JobDetailsCard = lazy(() => import("../components/JobDetailsCard"));

const JobDetails = () => {
  const form = useRef<HTMLFormElement | null>(null);
  const { jobId } = useParams<{ jobId: string }>();
  const parsedJobId = jobId ? parseInt(jobId, 10) : null;
  const [formData, setFormData] = useState<JobFormDatas>({
    name: '',
    email: '',
    phone: '',
    coverLetter: '',
    resume: null,
  });
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        resume: selectedFiles[0],
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setStatus(null);

    try {
      // Submitting application data
      const message = await submitApplication(formData, parsedJobId);
      setStatus(message);

      // Reset form data
      setFormData({
        name: '',
        email: '',
        phone: '',
        coverLetter: '',
        resume: null,
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Sending the form with emailjs, including file
      if (form.current) {
        emailjs.sendForm(
          'YOUR_SERVICE_ID',
          'YOUR_TEMPLATE_ID',
          form.current,
          'YOUR_PUBLIC_KEY'
        ).then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className="flex md:flex-row flex-col justify-center my-16">
      <Suspense fallback={<div>Loading...</div>}>
        <JobDetailsCard parsedJobId={parsedJobId} />
      </Suspense>
      <div className="md:w-100 md:mx-12 mx-4">
        <form
          className="border p-4 shadow-xl bg-gray-50"
          ref={form}
          onSubmit={handleSubmit}
          encType="multipart/form-data" // Ensure form can send files
        >
          <h1 className='text-center text-xl'>Apply for this position</h1>
          {error && <div className="mb-5 text-red-500 text-center">{error}</div>}
          {status && <div className="mb-5 text-green-500 text-center">{status}</div>}
          <div className="my-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">Full Name<span className='text-red-500'>*</span></label>
            <input
              type="text"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">Email<span className='text-red-500'>*</span></label>
            <input
              type="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">Phone<span className='text-red-500'>*</span></label>
            <input
              type="text"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">Cover Letter</label>
            <textarea
              id="message"
              rows={4}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              name="coverLetter"
              value={formData.coverLetter}
              onChange={handleChange}
            />
          </div>
          <div className="mb-5">
            <label className="block mb-2 text-sm font-medium text-gray-900">Upload Resume</label>
            <input
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              type="file"
              ref={fileInputRef}
              name="resume" // Ensure the name matches the field in your EmailJS template
              onChange={handleFileChange}
            />
          </div>
          <div className="flex items-start mb-5">
            <div className="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                value=""
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                required
              />
            </div>
            <label className="ms-2 text-sm font-medium text-gray-900">
              By using this form you agree with the storage and handling of your data by this website. <span className='text-red-500'>*</span>
            </label>
          </div>
          <button type="submit" className="text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default JobDetails;
