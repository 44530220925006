import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AiFillFilePdf } from "react-icons/ai";
import "../utils/styles/accordion.css";
import { AccordionProps } from "../helpers/types";

const Accordion = ({ items, initialOpenIndex }: AccordionProps) => {
  const [openIndex, setOpenIndex] = useState<number | null>(initialOpenIndex);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    contentRefs.current.forEach((content, index) => {
      if (content) {
        content.style.height =
          openIndex === index ? `${content.scrollHeight}px` : "0px";
      }
    });
  }, [openIndex]);

  return (
    <div className="space-y-2">
      {items.map((item, index) => (
        <div key={index} className="border border-gray-200 rounded-lg">
          <button
            onClick={() => handleToggle(index)}
            className="w-full px-4 py-3 text-left bg-gray-50 hover:bg-gray-200 rounded-t-lg flex justify-between items-center focus:outline-none"
          >
            <span
              className={`transition-colors duration-300 ${
                openIndex === index ? "text-primary" : "text-slate-900"
              }`}
            >
              {item.title}
            </span>
            <span>
              {openIndex === index ? (
                <FaChevronUp className="w-9 h-9 bg-primary rounded-image shadow-md text-white text-sm p-3" />
              ) : (
                <FaChevronDown className="w-9 h-9 bg-gray-200 rounded-image shadow-md text-slate-900 text-xs p-3" />
              )}
            </span>
          </button>
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            className="overflow-hidden transition-height duration-300 ease-in-out"
          >
            <div className="px-4 py-8 bg-white">
              <div className="flex flex-wrap flex-1 md:gap-y-2 lg:gap-1 child-border-reset">
                {item.quarters.map((quarter, quarterIndex) => (
                  <div
                    key={quarterIndex}
                    className="border-0 lg:border lg:border-gray-200 lg:border-l-0 lg:border-t-0 lg:border-b-0 lg: pr-4 pl-4 mb-4 w-full md:w-[49.8%]"
                  >
                    <label className="text-lg font-medium mb-3 text-left text-primary block">
                      {quarter.label}
                    </label>
                    <div className="space-y-6">
                      {quarter.reports.map((report, reportIndex) => (
                        <div key={reportIndex} className="text-center">
                          {report.label !== "NA" ? (
                            <a
                              href={report.file}
                              download
                              className="text-slate-900 hover:text-slate-950 qtpdfBtn"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <AiFillFilePdf className="mr-1 pdf-icon" />
                              {report.label}
                            </a>
                          ) : (
                            <span className="text-slate-900 hover:text-slate-950 qtpdfBtn hover:transform-none bg-gray-100 !shadow-none">
                              <AiFillFilePdf className="mr-1 pdf-icon" />
                              {report.label}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
