import { useState } from "react";
import ContactCard from "../components/ContactCard";
import { ClientFormDatas } from "../helpers/types";
import { submitContactForm } from "../helpers/api";
import Container from "../components/Container";

export default function ContactUs() {
  const [formData, setFormData] = useState<ClientFormDatas>({
    name: "",
    email: "",
    phone: "",
    subject: "",
  });
  const [status, setStatus] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const message = await submitContactForm(formData);
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
      });
      setStatus(message);
    } catch (error) {
      setStatus("There was an error submitting the form. Please try again.");
    }
  };

  return (
    <>
      <Container>
        <h1 className="text-4xl font-extrabold my-12">Contact Us</h1>
        <div className="flex items-center justify-evenly lg:flex-row flex-col gap-8">
          <div className="w-full lg:w-1/2">
            <ContactCard />
          </div>
          <div className="w-full lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15083.000029286932!2d72.834437!3d19.074726!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c91202520dbb%3A0x762b4c2b09e8c99d!2sGOLDEN%20LEGAND%20LEASING%20AND%20FINANCE%20LTD!5e0!3m2!1sen!2sus!4v1721906748798!5m2!1sen!2sus"
              loading="lazy"
              className="mt-4 lg:h-[700px] h-[350px] w-full"
              title="map"
            ></iframe>
          </div>
        </div>
        <div className="py-16 px-14 md:px-24 min-h-128 bg-gray-100 rounded-xl max-w-5xl m-auto my-16">
          <h1 className="md:text-5xl text-4xl font-bold">Leave Us a Message</h1>
          <h3 className="text-lg font-semibold text-gray-500 pt-2">
            Have a project in mind? To request a quote contact us directly or
            fill out the form and let us know how we can help.
          </h3>
          {status && <div className="text-green-500">{status}</div>}
          <form
            className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 font-semibold"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 font-semibold"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 font-semibold"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 font-semibold"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <button
              type="submit"
              className="text-white bg-green-500 w-44 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Submit
            </button>
          </form>
        </div>
      </Container>
    </>
  );
}
